export const apiFetch = async (url, options = {}) => {
    return fetch(
        url,
        {
            method: 'GET',
            mode: 'cors',
            ...options,
            ...(options.body ? {body: JSON.stringify(options.body)} : {}),
            ...{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...options.headers
                }
            }
        })
        .then(responseHandler);
};

export const responseHandler = async response => {
    const status = response.status;
    const body = await response.json().catch(error => error);
    if(status >= 400) return Promise.reject(body);
    return {status, body};
};